<template>
  <v-footer
    class="tnf-footer"
  >
    <template>
        <v-layout wrap>
          <v-flex xs12 sm12 md1 lg1 class="hidden-sm-and-down hidden-md-and-down"></v-flex>
          <v-flex xs12 sm12 md1 lg1 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left">关于我们</h5>
              <ul class="tnf-footer-nav text-left">
                <li><a href="/about">公司介绍</a></li>
                <li><a href="/story">品牌故事</a></li>
                <li><a href="/history">发展历程</a></li>
                <li><a href="/honor">企业荣誉</a></li>
                <li><a href="/assistant">门窗管家</a></li>
                <li><a href="/partner">合作伙伴</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg1 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left">产品中心</h5>
              <ul class="tnf-footer-nav text-left" v-if="info.product">
                <li v-for="cat in info.product.category.cn" :key="cat.id"><a :href="'/products/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md1 lg1 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left">案例中心</h5>
              <ul class="tnf-footer-nav text-left" v-if="info.cases">
                <li v-for="cat in info.cases.category.cn" :key="cat.id"><a :href="'/cases/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md1 lg1 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left">无忧服务</h5>
              <ul class="tnf-footer-nav text-left">
                <li><a href="http://hxc.trybachina.com" target="_blank">焕新窗</a></li>
                <li><a href="/quick_change">快窗闪换</a></li>
                <li><a href="/unify">解决方案</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md1 lg1 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left"><a href="/join" style="color:#fff;">招商加盟</a></h5>
              <ul class="tnf-footer-nav text-left">
                <li><a href="/join#condition">加盟条件</a></li>
                <li><a href="/join#process">加盟流程</a></li>
                <li><a href="/join#support">盟商支持</a></li>
                <li><a href="/join#want">我要加盟</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md1 lg1 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left">资讯动态</h5>
              <ul class="tnf-footer-nav text-left">
                <li><a href="/news">新闻资讯</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md1 lg1 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 tnf-footer-name text-left">联系我们</h5>
              <ul class="tnf-footer-nav text-left">
                <li><a href="/hr">企业招聘</a></li>
                <li><a href="/contact">联系方式</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md3 lg3 pl-2 class="hidden-sm-and-down hidden-md-and-down">
            <v-layout>
              <v-flex md5 lg5>
                <img style="max-width:120px;" :src="info.qrcode" />
              </v-flex>
              <v-flex md7 lg7 pl-2 class="text-right">
                <ul class="tnf-footer-tels">
                  <li>销售热线</li>
                  <li class="tnf-footer-tel">400 161 0919</li>
                  <li>售后服务</li>
                  <li class="tnf-footer-tel">400 180 0810</li>
                </ul>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md1 lg1 class="hidden-sm-and-down hidden-md-and-down"></v-flex>
          <v-flex xs12 sm12 md12 lg12 mt-6>
            <v-layout>
              <v-flex lg6 md6 sm12 xs12 class="tnf-footer-logo text-md-right text-lg-right text-xl-right text-center">
                <img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/footer_logo.png" />
              </v-flex>
              <v-flex lg6 md6 sm12 xs12 pl-12 class="text-left hidden-sm-and-down">
                <ul style="vertical-align:middle;">
                  <li class="tnf-links">友情链接：
                    <a v-for="link in info.links" :key="link.id" :href="link.url">{{ link.title }}</a>
                  </li>
                  <li class="tnf-addr">生产基地：{{info.addr}}</li>
                </ul>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="tnf-copyright text-center">
            Copyright © 2004-2020 TRYBA. All Rights Reserved.
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="tnf-miit text-center">
            <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
              <img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/miit.png" /> <span>{{info.icp}}</span>
            </a>
          </v-flex>
        </v-layout>
    </template>
  </v-footer>
</template>

<style>
.tnf-footer-name { color: #FFFFFF;font-size: 20px; font-weight: 500; }
.tnf-footer-nav a {color: #F1F8FC !important; font-size: 14px;}
.tnf-footer-tels {color: #FFFFFF;font-size: 14px;}
.tnf-footer-tels li {text-align: left;}
.tnf-footer-tel {font-size: 26px;}
.tnf-links a {margin-right: 5px; color: #FFFFFF;}
.tnf-links, .tnf-addr {color:#FFFFFF; font-size: 14px; height: 28px; line-height: 28px;}
.tnf-footer-logo img {vertical-align:middle; height:56px;}
.tnf-copyright {color:#657B88; font-size:14px;}
.tnf-miit {font-size:14px;}
.tnf-miit a {color:#657B88;}
.tnf-miit a img {vertical-align:middle; height:28px;margin-right: 5px;}
.tnf-miit span {display:inline-block; height:28px; line-height:28px; color:#657B88;}
</style>

<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      let links = []
      if (this.$store.getters.companyInfo.link) {
        links = this.$store.getters.companyInfo.link.filter(ele => ele.type === 0)
      }
      let info = this.$store.getters.companyInfo
      info.links = links
      return info
    }
  }
}
</script>
